import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import getPatientFavoriteDoctorList from "../../../utils/api/patient/FavoriteDoctor/GetPatientFavoriteDoctorList";
import deletePatientFavoriteDoctor from "../../../utils/api/patient/FavoriteDoctor/DeletePatientFavoriteDoctor";

export const DoktorlarimMenu = inject("store")(
  observer((props) => {
    const [doktorListesi, setDoktorListesi] = useState([]);
    const userIdFilter = [1172, 1174, 1175];

    useEffect(() => {
      // Update the document title using the browser API
      getPatientFavoriteDoctorListReq();
      // eslint-disable-next-line
    }, []);

    const getPatientFavoriteDoctorListReq = async () => {
      props.store.setLoadingBarStatus(false);
      if (props.store.patientId !== 0) {
        let favoriteDoctorListResponse = await getPatientFavoriteDoctorList(
          props.store.baseUrl,
          props.store.token,
          props.store.patientId
        );
        if (
          favoriteDoctorListResponse !== null &&
          favoriteDoctorListResponse !== undefined
        ) {
          if (favoriteDoctorListResponse.status === 200) {
            setDoktorListesi(
              favoriteDoctorListResponse.result.filter(
                (doktor) => !userIdFilter.includes(doktor.doctorId)
              )
            );
          } else if (favoriteDoctorListResponse.status === 401) {
            props.store.setUserLogout();
            props.history.push("/danisan/kullanici-girisi");
          }
        }
      }
      props.store.setLoadingBarStatus(true);
    };

    const deleteMyFavoriteDoctor = async (doctorId) => {
      props.store.setLoadingBarStatus(false);
      let deleteFavoriteDoctorResponse = await deletePatientFavoriteDoctor(
        props.store.baseUrl,
        props.store.token,
        props.store.patient[0].id,
        doctorId
      );
      if (
        deleteFavoriteDoctorResponse !== null &&
        deleteFavoriteDoctorResponse !== undefined
      ) {
        if (deleteFavoriteDoctorResponse.status === 200) {
          props.store.setMessage(
            "Favori doktorunuz başarıyla silindi.",
            true,
            "success"
          );
        } else {
          props.store.setMessage(
            "Favori doktorunuz silinirken hata oluştu.",
            true,
            "error"
          );
        }
      } else {
        props.store.setMessage(
          "Favori doktorunuz silinirken hata oluştu.",
          true,
          "error"
        );
      }
      props.store.setLoadingBarStatus(true);
    };

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">ADI SOYADI</TableCell>
                <TableCell align="left">UZMANLIK ALANI</TableCell>
                <TableCell align="left">MUAYENE ÜCRETİ</TableCell>
                <TableCell align="left">FAVORİYE ALINMA SAYISI</TableCell>
                <TableCell align="left">RANDEVU GEÇMİŞİ</TableCell>
                <TableCell align="left">RANDEVU İŞLEMLERİ</TableCell>
                <TableCell align="left">SİL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doktorListesi.map((doktor) => (
                <TableRow
                  key={doktor.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ width: "100px" }}>
                    <CardMedia
                      component="img"
                      sx={{
                        width: "75px",
                        height: "75px",
                        borderRadius: "75px",
                        border: "2px solid #f3f4f6",
                      }}
                      image={
                        doktor.doctorAvatar !== null
                          ? props.store.cdn + doktor.doctorAvatar
                          : "/assets/images/PersonIcon.png"
                      }
                      alt={doktor.doctorName}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {doktor.titleName ? doktor.titleName : ""}{" "}
                    {doktor.professionalName} {doktor.doctorName}{" "}
                    {doktor.doctorSurname}
                  </TableCell>
                  <TableCell align="left">{doktor.departmentName}</TableCell>
                  <TableCell align="left">
                    {doktor.examinationPrice} TL
                  </TableCell>
                  <TableCell align="left">
                    <IconButton aria-label="delete" size="small">
                      <FavoriteIcon
                        fontSize="inherit"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </IconButton>
                    <span style={{ fontSize: "12px" }}>
                      {doktor.doctorFavCount}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title="Randevu Geçmişini Görüntüle Görüntüle">
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={"/danisan/doktor/" + doktor.doctorId}
                        style={{ margin: "5px" }}
                      >
                        <InfoIcon style={{ color: "#000" }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title="Randevu işlemleri">
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={"/danisan/randevu-olustur/" + doktor.doctorId}
                        style={{ margin: "5px" }}
                      >
                        <CalendarMonthIcon style={{ color: "#000" }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title="Favori doktorlar listesinden sil">
                      <Button
                        variant="contained"
                        color="error"
                        style={{ margin: "5px" }}
                        onClick={() => deleteMyFavoriteDoctor(doktor.doctorId)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  })
);
