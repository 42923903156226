import { types } from 'mobx-state-tree'

import { User } from '../model/User'
import { Doctor } from '../model/Doctor/Doctor'
import { Patient } from '../model/Patient/Patient'
import { PatientAppointment } from '../model/Patient/PatientAppointment'

export const WholeMedicsStore = types
    .model("WholeMedicsStore", {
        baseUrl: types.string,
        cdn: types.string,
        isLogin: types.boolean,
        darkMode: types.boolean,
        token: types.string,
        messageInfo: types.string,
        messageDisplay: types.boolean,
        messageType: types.string,
        loadingBarStatus: types.optional(types.boolean, false),
        user: types.optional(types.array(User), []),
        patient: types.optional(types.array(Patient), []),
        patientId: types.optional(types.number, 0),
        doctor: types.optional(types.array(Doctor), []),
        appointmentList: types.optional(types.array(PatientAppointment), []),
        odemeBilgilendirmeMenuDisplay: types.optional(types.boolean, false),
        odemeSuresiDolduMenuDisplay: types.optional(types.boolean, false),
        appointmentCreateDate: types.optional(types.Date, new Date()),
        fileMessageUrl: types.optional(types.string, "")
    }).actions(self => ({
        setUserLogged() {
            self.isLogin = true
        },
        setUserLogout() {
            self.isLogin = false
            self.token = ""
            self.user = []
            self.loadingBarStatus = true
            self.patient = []  
            self.patientId = 0
            self.doctor = []
            self.appointmentList = []
            self.odemeBilgilendirmeMenuDisplay = false
            self.odemeSuresiDolduMenuDisplay = false
        },
        setDarkLightMode(inputMode) {
            self.darkMode = inputMode
        },
        setToken: (function (token) {
            self.token = token
        }),
        setMessage: (function (message, display, messageType) {
            self.messageInfo = message
            self.messageDisplay = display
            self.messageType = (messageType === undefined) ? "info" : messageType
        }),
        setLoadingBarStatus(loadingBar) {
            self.loadingBarStatus = loadingBar
        },
        setUser: (function (user) {
            self.user = []
            self.user.push(user)
        }),
        setDoctor: (function(inputDoctor){
            self.doctor = []
            self.doctor.push(inputDoctor)
        }),
        setPatient: (function(inputPatient){
            self.patient = []
            self.patient.push(inputPatient)
        }),
        setAppointmentList: (function(inputRandevuListesi){
            self.appointmentList = []
            self.appointmentList = inputRandevuListesi
        }),
        setPatientId: (function (inputPatientId){
            self.patientId= inputPatientId
        }),
        setOdemeBilgilendirmeMenuDisplay: (function(inputDisplay){
            self.odemeBilgilendirmeMenuDisplay = inputDisplay
        }),
        setOdemeSuresiDolduMenuDisplay: (function(inputDisplay){
            self.odemeSuresiDolduMenuDisplay = inputDisplay
        }),
        setAppointmentCreateDate: (function(inputDate){
            self.appointmentCreateDate = inputDate
        }),
        setFileMessageUrl: (function(inputUrl){
            self.fileMessageUrl = inputUrl
        })
    }))