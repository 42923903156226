import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';

import MapissoAlert from '../components/common/MapissoAlert'
import { CountDown } from '../components/common/CountDown';

import userSmsCodeVerify from '../utils/api/patient/UserSmsCodeVerify';
import userSmsSendAgain from '../utils/api/patient/UserSmsSendAgain'


class ActivationPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            smsCode: "",
            countInputDate: new Date(),
            tcKimlikNo: "",
            password: "",
            repassword: "",
            passwordHelperTitle: "",
            passwordErrorStatus: false,
            repasswordHelperTitle: "",
            repasswordErrorStatus: false,
            repasswordShowStatus: false,
            passwordShowStatus: false
        }
    }

    componentDidMount = async () => {
        if (this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/danisan");
        }
    }

    onSmsCodeValidate = async () => {
        this.props.store.setLoadingBarStatus(false)

        if (this.state.smsCode !== "") {

            let response = await userSmsCodeVerify(this.props.store.baseUrl, this.props.store.patientId, this.state.smsCode)

            if (response != null) {
                if (response.status === 200) {
                    this.props.store.setLoadingBarStatus(true)
                    this.props.history.push("/danisan/login");
                } else {
                    this.props.store.setToken("")
                    this.props.store.setMessage(response.data, true, "error")
                }
            } else {
                this.props.store.setMessage("Servis altyapısında problem oluştu", true, "error")
            }
        } else {
            this.props.store.setMessage("SMS kodunu girmeniz gerekmektedir..", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)
    }

    onSmsCodeTextInputChange = (event) => {
        this.setState({
            smsCode: event.target.value
        })
    }

    userSmsSendAgainRequest = async () => {
        this.props.store.setLoadingBarStatus(false)

        // console.log("this.props.store.baseUrl: ", this.props.store.baseUrl)

        let createNewSmsCode = await userSmsSendAgain(this.props.store.baseUrl, this.props.store.patientId)
        if (createNewSmsCode !== null && createNewSmsCode !== undefined) {
            if (createNewSmsCode.status === 200) {
                this.props.store.setMessage("Yeni kod gönderimi başarıyla tamamlandı.", true, "success")
            } else {
                this.props.store.setMessage("Yeni kod gönderilirken hata oluştu.", true, "error")
            }
        } else {
            this.props.store.setMessage("Yeni kod gönderilirken hata oluştu.", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)
    }




    render() {

        return (
            <div className="container mps-container bg-image">
                <Grid container style={{ width: "100%" }}>
                    <Grid item xs={4} className="height100vh" style={{ padding: "100px", background: "#fff", minWidth: "500px", paddingLeft: "50px", paddingTop: "50px" }}>
                        <Grid container spacing={0} style={{ marginTop: "20px", width: "250px", marginLeft: "50px" }}>
                            <Grid item xs={12}>
                                <img src='/assets/images/saglikcim-logo.jpg' alt="wholemedics" style={{ width: "100%", marginTop: "10px", marginLeft: "5px", marginBottom: "10px" }} />
                            </Grid>
                        </Grid>
                        <div style={{ paddingLeft: "50px" }}>
                            <Typography style={{ marginTop: "50px", fontWeight: "bold" }}>SMS Kodu Doğrulama</Typography>
                            <Typography variant='body1' >Cep telefonu numaranıza gönderilen sms kodunu giriniz.</Typography>
                            <TextField id="outlined-basic" variant="outlined" name="tckno" style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                value={this.state.smsCode}
                                label="SMS Kodu*" onChange={this.onSmsCodeTextInputChange}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Button color='primary' variant='contained' onClick={this.userSmsSendAgainRequest}>
                                                Tekrar Gönder
                                            </Button>
                                        </InputAdornment>
                                }}
                                autoComplete='one-time-code'
                            />

                            <CountDown />

                            <Button variant="contained" color="primary" onClick={this.onSmsCodeValidate}
                                style={{ width: "100%", height: "50px", fontWeight: "bold", marginTop: "10px" }}>
                                GİRİŞ YAP
                            </Button>
                            <LinearProgress style={{ display: (this.props.store.loadingBarStatus) ? "none" : "block", width: "calc(100% - 18px)", marginLeft: "9px" }} />

                        </div>
                        <div style={{ paddingLeft: "50px", width: "100%", marginTop: "75px", textAlign: "center" }}>
                            <Typography variant='body2'>Sağlıkçım'a kayıtlı değil misin?</Typography>
                            <Button variant="outlined" color="primary" to={"/danisan/kullanici-kaydi"} component={Link} target="_blank"
                                style={{ width: "100%", height: "50px", fontWeight: "600", color: "#000" }}>
                                Kayıt Ol
                            </Button>
                            <img style={{ position: "absolute", left: "150px", bottom: "20px", width: "200px", marginTop: "30px" }} alt="wholemedics" src='/assets/images/wholemedic-logo.png'></img>
                        </div>
                    </Grid>
                </Grid>
                <MapissoAlert />
            </div>
        )
    }
}

export default inject("store")(observer(ActivationPage));
