import React, { useEffect, useState } from 'react';
import { observer, inject } from "mobx-react"
import dayjs from 'dayjs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import eczanemLogo from './eczanem.png'

import QRCode from "react-qr-code";

import getAppointmentPrescription from '../../../utils/api/patient/Appointment/GetAppointmentPrescription'
import getPatientDoctorInfo from '../../../utils/api/patient/GetPatientDoctorInfo'
import { Doctor } from '../../../model/Doctor/Doctor';

export const ReceteMenu = inject("store")(observer((props) => {

    const [recete, setRecete] = useState(null)
    const [receteMedicineList, setReceteMedicineList] = useState([])
    const [doctor, setDoctor] = useState(Doctor.create({}))
    const [eczanemMedicineList, setEczanemMedicineList] = useState([])
    const [showDialog, setShowDialog] = useState(false)

    useEffect(() => {
        // Update the document title using the browser API
        getPrescriptionListReq()


        // eslint-disable-next-line
    }, []);

    const getPrescriptionListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getPrescriptionListResponse = await getAppointmentPrescription(props.store.baseUrl, props.store.token, props.store.patient[0].id, props.receteId)
        console.log("getPrescriptionListResponse: ", getPrescriptionListResponse)
        if (getPrescriptionListResponse !== null && getPrescriptionListResponse !== undefined) {
            if (getPrescriptionListResponse.status === 200) {

                if (getPrescriptionListResponse.data !== null) {
                    setRecete(getPrescriptionListResponse.data);
                    setReceteMedicineList(getPrescriptionListResponse.data.medicines);
                    const eczanemMedicines = getPrescriptionListResponse.data.medicines.filter((medicine) => medicine.link !== null); 
                    setEczanemMedicineList(eczanemMedicines);

                    let doctorResponse = await getPatientDoctorInfo(props.store.baseUrl, props.store.token,
                        props.store.patient[0].id, getPrescriptionListResponse.data.doctorId)
                    if (doctorResponse !== null && doctorResponse !== undefined) {
                        if (doctorResponse.status === 200) {
                            setDoctor(doctorResponse.data)
                            if (eczanemMedicines.length > 0) {
                                setShowDialog(true);
                            } else {
                                setTimeout(()=>window.print(), 500);
                            }
                        }
                    }
                }

            } else if (getPrescriptionListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const whenClosed = () => {
        setShowDialog(false);
        setTimeout(()=>window.print(), 500);
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <Typography style={{ fontWeight: "bold" }}>REÇETE</Typography>
                    <Typography variant='body2'>
                        WholeMedics AŞ'ye Ait MedScreenWM <br /> Platformunda İmzalanmıştır. SB Tescil No.********
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <QRCode value={"/danisan/recete/" + props.receteId} size={100} style={{ marginRight: "10px" }} />
                    <Typography variant='body2'>Tarih: {dayjs(new Date(recete?.appointmentDate)).format("DD.MM.YYYY")}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px" }}>
                <Grid item xs={5}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Doktor Bilgileri</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Dr. Adı Soyadı:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>{(doctor.title?.titleName ? (doctor.title.titleName + " " ) : "") +  doctor.professional.professionalName + " "
                                + doctor.name + " " + doctor.surname}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>İletişim Bilgileri:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {doctor.address.address + " " + doctor.address.neighbourhoodName + " " + doctor.address.districtName +
                                    " " + doctor.address.cityName} <br />
                                {doctor.email} <br />
                                {doctor.mobilePhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Uzmanlık Bilgisi:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {doctor.department?.departmentName}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Diploma No:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {doctor.diploma?.diplomaNo}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Hasta Bilgileri</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>T.C. No:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {props.store.patient[0].tckno}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Hasta Adı Soyadı:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {props.store.patient[0].name + " " + props.store.patient[0].surname}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Doğum Tarihi:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {dayjs(new Date(props.store.patient[0].birthDate)).format("DD.MM.YYYY")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ width: "100%", marginTop: "15px", border: "1px solid gray", padding: "10px" }}>
                <Grid item xs={12}>
                    <Typography variant='body2'><b>Tanı: </b>
                        {recete?.diagnosisCode + " " + recete?.diagnosisName}</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={0} style={{ width: "100%", marginTop: "15px", border: "1px solid gray", padding: "10px" }}>
                <Grid item xs={12}>
                    <Typography variant='body2'><b>Rp. </b></Typography>
                </Grid>
                <Grid item xs={12} style={{ display: (props.prescription === null) ? "block" : "none" }}>

                </Grid>
                <Grid item xs={12}>
                    <TableContainer >
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{ paddingLeft: "50px" }}>İLAÇ ADI</TableCell>
                                    <TableCell align="left">DOZ</TableCell>
                                    <TableCell align="left">PERİYOT</TableCell>
                                    <TableCell align="left">KUTU</TableCell>
                                    <TableCell align="left">KULLANIM ŞEKLİ</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {receteMedicineList.map((medicine, idx) => (
                                    <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left" style={{ paddingLeft: "50px" }}>
                                            <Typography>
                                                {medicine.medicineName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" >
                                            <Typography>{medicine.medicineDose}</Typography>
                                        </TableCell>
                                        <TableCell align="left" >
                                            <Typography>{medicine.medicinePeriod} </Typography>
                                        </TableCell>
                                        <TableCell align="left" >
                                            <Typography>{medicine.medicineCount}</Typography>
                                        </TableCell>
                                        <TableCell align="left" >
                                            <Typography>{medicine.medicineUsageName}</Typography>
                                        </TableCell>
                                        <TableCell align="left" >

                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <img src={props.store.cdn + doctor?.kase} style={{ width: "250px", float: "right" }} alt="kase" />
            <Dialog open={showDialog} maxWidth={"sm"} fullWidth onClose={whenClosed}>
                <DialogTitle disableTypography>
                    <DialogTitle id="customized-dialog-title" sx={{m: 0, p: 0}}>
                    <img alt="eczanem.saglikcim.net" src={eczanemLogo} style={{ width: '42px', height: '42px', marginInlineStart: 'auto', marginInlineEnd: '10px' }} />
                    Reçetedeki Ürünleri Satın Al
                    </DialogTitle>
                    <IconButton
                    aria-label="close"
                    onClick={whenClosed}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {eczanemMedicineList.map((medicine) => (<Button color="warning" style={{ width: "100%", marginTop: '12px' }} variant="contained" ><a style={{color: 'white'}} href={"https://eczanem.saglikcim.net/" + medicine.link} target="_blank" rel="noopener noreferrer">{medicine.medicineName}</a></Button>))}
                </DialogContent>
            </Dialog>
        </div>
    )
}))