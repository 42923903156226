import React, { useEffect, useState } from 'react';
import { observer, inject } from "mobx-react"
import dayjs from 'dayjs';

import { Grid, Typography } from '@mui/material';

import QRCode from "react-qr-code";

import getPatientInsuranceFormList from '../../../utils/api/patient/MedicalDocuments/GetPatientInsuranceFormList';
import getPatientDoctorInfo from '../../../utils/api/patient/GetPatientDoctorInfo'
import { Doctor } from '../../../model/Doctor/Doctor';

export const SigortaBilgiFormuMenu = inject("store")(observer((props) => {

    const [insuranceForm, setInsuranceForm] = useState(null)
    const [doctor, setDoctor] = useState(Doctor.create({}))

    useEffect(() => {
        // Update the document title using the browser API
        getInsuranceFormListReq()

        // eslint-disable-next-line
    }, []);

    const getInsuranceFormListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getInsuranceListResponse = await getPatientInsuranceFormList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        console.log("getInsuranceListResponse: ", getInsuranceListResponse)
        if (getInsuranceListResponse !== null && getInsuranceListResponse !== undefined) {
            if (getInsuranceListResponse.status === 200) {
                //setInsuranceForm(getInsuranceListResponse.data)
                let getInsuranceList = getInsuranceListResponse.data
                for (let index = 0; index < getInsuranceList.length; index++) {
                    const insuranceTemp = getInsuranceList[index];
                    if (insuranceTemp.appointmentId === props.sbfId) {
                        console.log("insuranceTemp: ", insuranceTemp)
                        setInsuranceForm(insuranceTemp)

                        let doctorResponse = await getPatientDoctorInfo(props.store.baseUrl, props.store.token,
                            props.store.patient[0].id, insuranceTemp.doctorId)
                        if (doctorResponse !== null && doctorResponse !== undefined) {
                            if (doctorResponse.status === 200) {
                                console.log("doctorResponse.data: ", doctorResponse.data)
                                setDoctor(doctorResponse.data)
                                window.print()
                            }
                        }
                    }
                }
            }else if (getInsuranceListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    return (
        <div>
            <Grid container spacing={0} style={{ marginTop: "0px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={4}>
                    <Typography variant='h6' style={{ fontWeight: "bold" }}>Sigorta Bilgi Formu</Typography>
                    <Typography variant='body2'>
                        WholeMedics AŞ'ye Ait MedScreenWM <br /> Platformunda İmzalanmıştır. SB Tescil No.********
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{ textAlign: "right" }}>
                    <QRCode value={"/danisan/sbf/" + props.sbfId} size={100} style={{ marginRight: "10px" }} />
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={12} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2'>Bu form hastadan alınan bilgiler, fiziki muayene bulguları, tetkik sonuçları ve poliklinik kayıtlarındaki
                        bilgiler doğrultusunda eksiksiz ve tam doldurulmalıdır.
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Sigorta Şirketi: </Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2'>{insuranceForm?.insuranceFirmName}</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Provizyon No:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2'></Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Provizyon/İrtibat Numaraları: </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Telefon Numarası: </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Faks Numarası: </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={1} style={{ borderRight: "1px solid gray", minWidth: "100px", maxWidth: "100px" }}>
                    <Typography variant='body2' className='vertical' style={{ fontWeight: "bold" }}>
                        Sağlık Kurumu Tarafından <br /> Doldurulacak Bölüm
                    </Typography>
                </Grid>
                <Grid item xs={11} style={{ minWidth: "calc(100% - 100px)", maxWidth: "calc(100% - 100px)" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Sağlık Kuruluş Adı: </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Kurum Kodu:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Telefon No:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Faks No</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' >
                                {(insuranceForm?.doctorTitleName ? (insuranceForm.doctorTitleName + " ") : "") + insuranceForm?.doctorProfessionalName + " " + insuranceForm?.doctorName + " " + insuranceForm?.doctorSurname}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>-</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                        {doctor?.mobilePhone}
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            -
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'><b>Sigortalının Adı-Soyadı:</b></Typography>
                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.patientName + " " + insuranceForm?.patientSurname}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'><b>Doğum Tarihi:</b> </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{dayjs(new Date(insuranceForm?.patientBirthDate)).format("DD.MM.YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'><b>Cinsiyet:</b> </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.patientGender}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Poliçe No:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.policyno}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'><b>T.C. No:</b> </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.patientTckno}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Adres:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.patientAddress + " " +
                                insuranceForm?.patientDistrict + "/" + insuranceForm?.patientCity}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>E-posta Adresi:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.patientEmail}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant='body2'><b>Başvuru Tarihi:</b> </Typography>
                        </Grid>
                        <Grid item xs={2} >
                            {dayjs(new Date(insuranceForm?.appliedDate)).format("DD.MM.YYYY")}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Planlanan Yatış/Çıkış Tarihi:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ padding: "10px" }}>

                        </Grid>
                        <Grid item xs={3} style={{ padding: "10px" }}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid spacing={1} container style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={12} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Ayakta tedaviler için talep edilen ücret bilgileri</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Doktor:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>İlaç:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Röntgen:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Sarf/Malzeme:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Laboratuvar:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Diğer/Açıklayınız:</Typography>
                </Grid>
                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Toplam:</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={1} style={{ borderRight: "1px solid gray", minWidth: "100px", maxWidth: "100px" }}>
                    <Typography variant='body2' className='vertical' style={{ fontWeight: "bold" }}>
                        Muayene Eden Hekim Tarafından <br /> Doldurulacak Bölüm
                    </Typography>
                </Grid>
                <Grid item xs={11} style={{ minWidth: "calc(100% - 100px)", maxWidth: "calc(100% - 100px)" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'><b>Hastanın Şikayeti / Öyküsü:</b> </Typography>
                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.complaint}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Şikayetin Başlangıç Tarihi:</Typography>
                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray", paddingRight: "15px" }}>
                            {dayjs(new Date(insuranceForm?.complaintStartdate)).format("DD.MM.YYYY")}
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Daha önce aynı şikayet/hastalık nedeni ile doktor başvurusu,
                                tetkik ve tedavi uygulandı mı? (Başvurulan sağlık kurumu  doktoru adı) :</Typography>
                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray", paddingRight: "15px" }}>
                            <Typography variant='body2'>{insuranceForm?.previousDoctorApplied}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Özgeçmiş / Kullandığı İlaçlar:</Typography>

                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.patientHistory}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Fizik Muayene Bulguları:</Typography>
                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Tetkikler/Sonuçlar:</Typography>
                        </Grid>
                        <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>
                            <Typography>{insuranceForm?.treatment}</Typography>
                        </Grid>

                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Ön Tanı / Tanı:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.diagnosis}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray", borderLeft: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>ICD 10:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>{insuranceForm?.diagnosisCode}</Typography>
                        </Grid>
                        <Grid item xs={3} >
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Planlanan Tedavi / İşlem:</Typography>
                        </Grid>
                        <Grid item xs={9} >
                            <Typography variant='body2'>
                                {insuranceForm?.treatment}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Doktor Adı-Soyadı:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>
                                {(insuranceForm?.doctorTitleName ? (insuranceForm.doctorTitleName + " ") : "") + insuranceForm?.doctorProfessionalName + " " + insuranceForm?.doctorName + " " + insuranceForm?.doctorSurname}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Uzmanlık Dalı:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>
                                {insuranceForm?.doctorDepartmentName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>İrtibat Telefonu:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>
                                {doctor?.mobilePhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ height: "100px" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>İmza / Kaşe:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={props.store.cdn + doctor?.kase} style={{width: "250px", float: "right"}} alt="kase"/>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={2} style={{ borderLeft: "1px solid gray", borderRight: "1px solid gray" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Operatör: </Typography>
                        </Grid>

                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Anestezi: </Typography>
                        </Grid>

                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Asistan: </Typography>
                        </Grid>

                        <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}> </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2'>
                                *Doktor ve/ veya ekibi anlaşmasız ise sigortalının tedavisi için talep edilecek ücreti yukarıda belirtecektir.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={12}>
                    <Typography variant='body2'>
                        Anlaşmalı sağlık kuruluşlarında yapılan her türlü tedavi / işlem anlaşması bulunmayan / anlaşmalı sağlık kurum ile Sigorta Şirketi
                        arasındaki fiyat protokolüne göre hizmet vermeyi kabul etmeyen kadrosuz bir hekim ve / veya ekibi tarafından gerçekleştirildiğinde
                        ilgili hekim ve / veya ekibine ödenecek tutar poliçe özel ve genel şartları dahilinde değerlendirileceği için sigorta şirketi ile irtibta
                        geçilmesi gerekmektedir.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                <Grid item xs={12}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>
                        Sigortalı / Kanuni Temsilcisinin Beyanı
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        Yukarıda verilen bilgilerin eksiksiz ve doğru olduğunu, sigorta şirketinin kendim ve bağımlılarım hakkında bu / diğer rahatsızlıklara ilişkin
                        tüm bilgi / belgeleri, tüm sağlık kuruluşlarından isteme hakkı olduğunu beyan / kabul ederim.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2'>
                        <b>Sigortalı / Kanuni Temsilcisinin Adı Soyadı: {insuranceForm?.patientName + " " + insuranceForm?.patientSurname} </b>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2'><b>Tarih: </b> {dayjs(new Date()).format("DD.MM.YYYY")} </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2' style={{ fontWeight: "bold" }}>İmza: </Typography>
                </Grid>
            </Grid>
        </div>
    )
}))