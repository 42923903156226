import React, { useEffect, useRef, useState } from 'react';
import { observer, inject } from "mobx-react"
import dayjs from 'dayjs';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { AppBar, Button, Dialog, DialogActions, DialogContent, Toolbar } from '@mui/material';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete'

import { FaFilePrescription, FaFileContract, FaFileInvoice, FaFileAlt } from 'react-icons/fa'

import getPatientPrescriptionList from '../../../utils/api/patient/MedicalDocuments/GetPatientPrescriptionList'
import getPatientDiagnosisList from '../../../utils/api/patient/MedicalDocuments/GetPatientDiagnosisList'
import getPatientExaminationList from '../../../utils/api/patient/MedicalDocuments/GetPatientExaminationList'
import getPatientInsuranceFormList from '../../../utils/api/patient/MedicalDocuments/GetPatientInsuranceFormList'
import getPatientImageList from '../../../utils/api/patient/MedicalDocuments/GetPatientImageList'
import getPatientReportList from '../../../utils/api/patient/MedicalDocuments/GetPatientReportList'
import getPatientFileList from '../../../utils/api/patient/File/GetPatientFileList'
import deletePatientFile from '../../../utils/api/patient/File/DeletePatientFile'
import Upload from '../../upload/Upload';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export const TıbbiBelgelerimMenu = inject("store")(observer((props) => {

    const [receteList, setReceteList] = useState([])
    const [sbfList, setSbfList] = useState([])
    const [raporList, setRaporList] = useState([])
    const [taniList, setTaniList] = useState([])
    const [fileList, setFileList] = useState([])
    const [tetkikList, setTetkikList] = useState([])
    const [imageTalepList, setImageTalepList] = useState([])

    const [showTetkikDetayMenuDisplay, setShowTetkikDetayMenuDisplay] = useState(false)
    const [selectedTetkik, setSelectedTetkik] = useState(null)

    const fileTypeList = useRef([
        { id: 0, name: "Belirsiz", show: false },
        { id: 1, name: "Doktor Diploması", show: false },
        { id: 2, name: "Doktor Kaşesi", show: false },
        { id: 3, name: "Hasta Tahlil", show: true },
        { id: 4, name: "Klinik", show: false },
        { id: 5, name: "Bilgisayarlı Tomografi", show: true },
        { id: 6, name: "Ultrason", show: true },
        { id: 7, name: "Renkli Doppler Ultrason", show: true },
        { id: 8, name: "Düz Grafi", show: true },
        { id: 9, name: "Doktor Avatar", show: false },
        { id: 10, name: "Özel Grafiler", show: true },
        { id: 11, name: "Manyetik Rezonans", show: true },
        { id: 12, name: "Hasta Fotoğrafı", show: false }
    ])

    const [selectedFileType, setSelectedFileType] = useState(0)


    useEffect(() => {
        // Update the document title using the browser API
        getPrescriptionListReq()
        getDiagnosisListReq()
        getExaminationListReq()
        getInsuranceFormListReq()
        getImageListReq()
        geReportListReq()
        getFileListReq()
        // eslint-disable-next-line
    }, []);

    const getPrescriptionListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getPrescriptionListResponse = await getPatientPrescriptionList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        //console.log("getPrescriptionListResponse: ", getPrescriptionListResponse)
        if (getPrescriptionListResponse !== null && getPrescriptionListResponse !== undefined) {
            if (getPrescriptionListResponse.status === 200) {
                setReceteList(getPrescriptionListResponse.data)
            } else if (getPrescriptionListResponse.status === 401) {
                props.store.setUserLogout()
                props.history.push("/danisan/kullanici-girisi")
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const getDiagnosisListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getDiagnosisListResponse = await getPatientDiagnosisList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        ///console.log("getDiagnosisListResponse: ", getDiagnosisListResponse)
        if (getDiagnosisListResponse !== null && getDiagnosisListResponse !== undefined) {
            if (getDiagnosisListResponse.status === 200) {
                setTaniList(getDiagnosisListResponse.data)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const getExaminationListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getExaminationListResponse = await getPatientExaminationList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        //console.log("getExaminationListResponse: ", getExaminationListResponse)
        if (getExaminationListResponse !== null && getExaminationListResponse !== undefined) {
            if (getExaminationListResponse.status === 200) {
                setTetkikList(getExaminationListResponse.result)
            }
        }


        props.store.setLoadingBarStatus(true)
    }

    const getImageListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getImageListResponse = await getPatientImageList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        //console.log("getImageListResponse: ", getImageListResponse.result)
        if (getImageListResponse !== null && getImageListResponse !== undefined) {
            if (getImageListResponse.status === 200) {
                setImageTalepList(getImageListResponse.result)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const getInsuranceFormListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getInsuranceListResponse = await getPatientInsuranceFormList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        //console.log("getInsuranceListResponse: ", getInsuranceListResponse)
        if (getInsuranceListResponse !== null && getInsuranceListResponse !== undefined) {
            if (getInsuranceListResponse.status === 200) {
                setSbfList(getInsuranceListResponse.data)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    /*const getMedicineListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getMedicineListResponse = await getPatientMedicineList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        // console.log("getMedicineListResponse: ", getMedicineListResponse)

        props.store.setLoadingBarStatus(true)
    }*/

    const geReportListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getReportListResponse = await getPatientReportList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        //console.log("getReportListResponse: ", getReportListResponse)
        if (getReportListResponse !== null && getReportListResponse !== undefined) {
            if (getReportListResponse.status === 200) {
                setRaporList(getReportListResponse.data)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const getFileListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getFileListResponse = await getPatientFileList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        //console.log("getFileListResponse: ", getFileListResponse)
        if (getFileListResponse !== null && getFileListResponse !== undefined) {
            if (getFileListResponse.status === 200) {
                let fileList = []
                for (let i = 0; i < getFileListResponse.data.length; i++) {
                    const fileTemp = getFileListResponse.data[i];
                    let fileNameArray = fileTemp.fileName.split("/");
                    let fileName = fileNameArray[fileNameArray.length - 1]
                    fileTemp.name = fileName
                    fileList.push(fileTemp)
                }
                setFileList(fileList)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickDeleteFile = async (fileId) => {
        props.store.setLoadingBarStatus(false)

        let fileDeleteResponse = await deletePatientFile(props.store.baseUrl, props.store.token, props.store.patient[0].id,
            fileId)

        if (fileDeleteResponse !== null && fileDeleteResponse !== undefined) {
            if (fileDeleteResponse.status === 200) {
                props.store.setMessage("Dosya başarıyla silindi. ", true, "success")
                await getFileListReq()
            } else {
                props.store.setMessage("Dosya silinirken hata oluştu. ", true, "error")
            }
        } else {
            props.store.setMessage("Dosya silinirken hata oluştu. ", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const onChangeFileTypeSelect = (event) => {
        setSelectedFileType(event.target.value)
    }

    const updateFileList = (inputFile) => {
        // console.log("input file: ", inputFile)
        setFileList(inputFile)
    }

    const onClickShowTetkikDetay = (tetkik) => {
        console.log("selectedTetkik: ", tetkik)
        setShowTetkikDetayMenuDisplay(true)
        setSelectedTetkik(tetkik)
    }

    return (
        <Grid container spacing={3} style={{ padding: "20px", paddingLeft: "0" }}>
            <Grid item lg={12} sm={12} xl={12} xs={12} >
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFilePrescription style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Reçetelerim</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>RANDEVU ZAMANI</TableCell>
                                                        <TableCell align="right">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="right">DOKTOR UZMANLIK ALANI</TableCell>
                                                        <TableCell align="right">TANI BİLGİSİ</TableCell>
                                                        <TableCell align="right">İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(receteList !== null) ? receteList.map((recete, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell component="th" scope="row">
                                                                {dayjs(new Date(recete.appointmentDate)).format("DD.MM.YYYY HH:mm")}
                                                            </TableCell>
                                                            <TableCell align="right">{recete.doctorName + " " + recete.doctorSurname}</TableCell>
                                                            <TableCell align="right">{recete.departmentName}</TableCell>
                                                            <TableCell align="right">{recete.diagnosisName}</TableCell>
                                                            <TableCell align='right'>
                                                                <Tooltip title="Reçete Görüntüle">
                                                                    <IconButton color="primary" aria-label="upload picture" component={RouterLink}
                                                                        target="_blank" to={"/danisan/recete/" + recete.appointmentId} >
                                                                        <ArticleIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell>Henüz reçete bilgisi kaydedilmedi.</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFileContract style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Sigorta Bilgi Formlarım</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>RANDEVU ZAMANI</TableCell>
                                                        <TableCell align="right">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="right">DOKTOR UZMANLIK ALANI</TableCell>
                                                        <TableCell align="right">TANI BİLGİSİ</TableCell>
                                                        <TableCell align="right">İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {(sbfList !== null) ? sbfList.map((sbf, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell component="th" scope="row">
                                                                {dayjs(new Date(sbf.appliedDate)).format("DD.MM.YYYY HH:mm")}
                                                            </TableCell>
                                                            <TableCell align="right">{sbf.doctorName + " " + sbf.doctorSurname}</TableCell>
                                                            <TableCell align="right">{sbf.doctorDepartmentName}</TableCell>
                                                            <TableCell align="right">{sbf.diagnosis}</TableCell>
                                                            <TableCell align='right'>
                                                                <Tooltip title="Sigorta Bilgi Formu Görüntüle">
                                                                    <IconButton color="primary" aria-label="upload picture" component={RouterLink}
                                                                        to={"/danisan/sbf/" + sbf.appointmentId} target="_blank">
                                                                        <ArticleIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell>Henüz sigorta bilgi formu kaydedilmedi.</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFileInvoice style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Raporlarım</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>RANDEVU ZAMANI</TableCell>
                                                        <TableCell align="right">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="right">DOKTOR UZMANLIK ALANI</TableCell>
                                                        <TableCell align="right">RAPOR KARARI</TableCell>
                                                        <TableCell align="right">RAPOR BAŞLANGIÇ TARİHİ</TableCell>
                                                        <TableCell align="right">RAPOR BİTİŞ TARİHİ</TableCell>
                                                        <TableCell align="right">İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(raporList !== null) ? raporList.map((rapor, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell component="th" scope="row">
                                                                {dayjs(new Date(rapor.appointmentDate)).format("DD.MM.YYYY HH:mm")}
                                                            </TableCell>
                                                            <TableCell align="right">{(rapor.titleName ? (rapor.titleName + " ") : "") + rapor.doctorName + " " + rapor.doctorSurname}</TableCell>
                                                            <TableCell align="right">{rapor.departmentName}</TableCell>
                                                            <TableCell align="right">{rapor.reportDecision}</TableCell>
                                                            <TableCell align="right">{dayjs(new Date(rapor.reportStartDate)).format("DD.MM.YYYY")}</TableCell>
                                                            <TableCell align="right">{dayjs(new Date(rapor.reportEndDate)).format("DD.MM.YYYY")}</TableCell>
                                                            <TableCell align='right'>
                                                                <Tooltip title="Rapor Görüntüle">
                                                                    <IconButton color="primary" aria-label="upload picture" component={RouterLink}
                                                                        target="_blank" to={"/danisan/rapor/" + rapor.appointmentId} >
                                                                        <ArticleIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell>Henüz rapor kaydedilmedi.</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFileInvoice style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Tanı Bilgilerim</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>RANDEVU ZAMANI</TableCell>
                                                        <TableCell align="right">DOKTOR ADI SOYADI</TableCell>
                                                        <TableCell align="right">DOKTOR UZMANLIK ALANI</TableCell>
                                                        <TableCell align="right">TANI AÇIKLAMASI</TableCell>
                                                        <TableCell align="right">TANI TARİHİ</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(taniList !== null) ?
                                                        taniList.map((tani, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell component="th" scope="row">
                                                                    {dayjs(new Date(tani.appointmentDate)).format("DD.MM.YYYY HH:mm")}
                                                                </TableCell>
                                                                <TableCell align="right">{tani.professionalName + " " + tani.doctorName + " " + tani.doctorSurname}</TableCell>
                                                                <TableCell align="right">{tani.departmentName}</TableCell>
                                                                <TableCell align="right">{tani.diagnosisDesc}</TableCell>
                                                                <TableCell align="right">{dayjs(new Date(tani.diagnosisDate)).format("DD.MM.YYYY")}</TableCell>
                                                            </TableRow>
                                                        )) :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell component="th" scope="row">
                                                                Henüz tanı bilgisi kaydedilmedi.
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFileAlt style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Dosyalarım</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>DOSYA ADI</TableCell>
                                                        <TableCell>KATEGORİ</TableCell>
                                                        <TableCell align="right">YÜKLEME TARİHİ</TableCell>
                                                        <TableCell align="right">İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(fileList !== null) ? fileList.map((file, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell component="th" scope="row">
                                                                {file?.name}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {fileTypeList.current.map((fileType, idx2) => (
                                                                    (fileType.id === file.fileType) ?
                                                                        <Typography key={idx2}>{fileType.name}</Typography> : null
                                                                ))}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {dayjs(new Date(file?.createdDate)).format("DD.MM.YYYY HH:MM")}

                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                <Tooltip title="Dosyayı Görüntüle">
                                                                    <IconButton color="primary">
                                                                        <Link href={props.store.cdn + file.fileName} target="_blank"
                                                                            variant="body2" style={{ paddingTop: "8px" }}>
                                                                            <ArticleIcon color='primary' />
                                                                        </Link>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Dosyayı Sil">
                                                                    <IconButton color="primary"
                                                                        onClick={() => onClickDeleteFile(file.fileId)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell>
                                                                Henüz dosya kayıt edilmemiştir.
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFileAlt style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Tetkik Listesi</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>TALEP EDEN DOKTOR BİLGİSİ</TableCell>
                                                        {/*
                                                            <TableCell>TETKİK İSMİ</TableCell>
                                                            <TableCell>TETKİK KATEGORİ</TableCell>
                                                        */}
                                                        <TableCell>TALEP TARİHİ</TableCell>
                                                        <TableCell>TETKİK LİSTE FİYATI</TableCell>
                                                        <TableCell>TETKİK GERÇEKLEŞEN FİYAT</TableCell>
                                                        <TableCell>TETKİK DURUM</TableCell>
                                                        <TableCell>İŞLEMLER</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(tetkikList !== null) ?
                                                        tetkikList.map((tetkik, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>{tetkik.professionalName + " " + tetkik.doctorName + " " + tetkik.doctorSurname}</TableCell>
                                                                <TableCell>{tetkik.appointmentDate}</TableCell>
                                                                <TableCell>{tetkik.estimatedPrice} TL</TableCell>
                                                                <TableCell>{tetkik.actualPrice} TL</TableCell>
                                                                <TableCell>{tetkik.examStatusName}</TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Tetkik Sonuçlarını Görüntüle">
                                                                        <IconButton color="primary" aria-label="upload picture"
                                                                            onClick={() => onClickShowTetkikDetay(tetkik)}>
                                                                            <ArticleIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                        :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell>
                                                                <Typography variant='body2'>
                                                                    Bu görüşmede kayıt altına alınmış bir tetkik bilgisi bulunmamaktadır
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ background: "#fff", boxShadow: "none" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <FaFileAlt style={{ fontSize: "24px" }} />
                                        <Typography style={{ fontWeight: "bold", marginLeft: "15px" }}>Görüntüleme Talepleri</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>TALEP EDEN DOKTOR BİLGİSİ</TableCell>
                                                        <TableCell>GÖRÜNTÜLEME İSMİ</TableCell>
                                                        <TableCell>GÖRÜNTÜLEME KATEGORİ</TableCell>
                                                        <TableCell>GÖRÜNTÜLEME TARİHİ</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(imageTalepList !== null) ?
                                                        imageTalepList.map((image, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>{image.doctorProfessionalName + " " + image.doctorName + " " + image.doctorSurname}</TableCell>
                                                                <TableCell>{image.imageName}</TableCell>
                                                                <TableCell>{image.categoryName}</TableCell>
                                                                <TableCell>{image.appointmentDate}</TableCell>
                                                            </TableRow>
                                                        ))
                                                        :
                                                        <TableRow key={"data-not-found"}>
                                                            <TableCell>
                                                                <Typography variant='body2'>
                                                                    Kayıt altına alınmış bir görüntüleme bilgisi bulunmamaktadır
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body1' style={{ fontWeight: "bold", marginBottom: "10px" }}>Dosya Yükleme Paneli</Typography>
                                <FormControl fullWidth style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <InputLabel id="city-select-label">Dosya Kategorisi</InputLabel>
                                    <Select labelId="city-select-label" label="Dosya Kategorisi"
                                        value={selectedFileType} id="city-select" style={{ background: "#fff" }}
                                        onChange={onChangeFileTypeSelect} MenuProps={MenuProps}>
                                        <MenuItem key={"emptyil"} value={0}>Kategori Seçiniz</MenuItem>
                                        {fileTypeList.current.map((fileType, idx) => (
                                            (fileType.show) ?
                                                <MenuItem key={idx} value={fileType.id}>{fileType.name}</MenuItem> : null
                                        ))}
                                    </Select>
                                </FormControl>
                                <Upload fileType={selectedFileType} setFileList={updateFileList}></Upload>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Dialog open={showTetkikDetayMenuDisplay} onClose={() => setShowTetkikDetayMenuDisplay(false)} fullWidth maxWidth="lg">
                <AppBar color="info" sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Seçilen Tetkik Detay Bilgisi
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>TETKİK KATEGORİ</TableCell>
                                    <TableCell>TETKİK İSMİ</TableCell>
                                    <TableCell>TETKİK LİSTE FİYATI</TableCell>
                                    <TableCell>TETKİK GERÇEKLEŞEN FİYAT</TableCell>
                                    <TableCell>TETKİK DURUM</TableCell>
                                    <TableCell>TETKİK DOSYASI GÖRÜNTÜLE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(selectedTetkik !== null) ?
                                    selectedTetkik.items.map((tetkikItem, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>{tetkikItem.categoryName}</TableCell>
                                            <TableCell>{tetkikItem.examinationDesc}</TableCell>
                                            <TableCell>{tetkikItem.estimatedPrice} TL</TableCell>
                                            <TableCell>{tetkikItem.actualPrice} TL</TableCell>
                                            <TableCell>{tetkikItem.examStatusName}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Tetkik Sonuçlarını Görüntüle">
                                                    <IconButton color="primary" aria-label="upload picture"
                                                        component={Link} disabled={(tetkikItem.examFiles.length > 0) ? false : true}
                                                        href={props.store.cdn + tetkikItem.examFiles[0]} target="_blank">
                                                        <ArticleIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow key={"data-not-found"}>
                                        <TableCell>
                                            <Typography variant='body2'>
                                                Bu görüşmede kayıt altına alınmış bir tetkik bilgisi bulunmamaktadır
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="primary" onClick={() => setShowTetkikDetayMenuDisplay(false)}>
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}))