import React, { useEffect, useState } from 'react';
import { observer, inject } from "mobx-react"

import { Grid, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';

import QRCode from "react-qr-code";

import getPatientReportList from '../../../utils/api/patient/MedicalDocuments/GetPatientReportList'
import getPatientDoctorInfo from '../../../utils/api/patient/GetPatientDoctorInfo'
import { Doctor } from '../../../model/Doctor/Doctor';

export const RaporMenu = inject("store")(observer((props) => {

    const [selectedReport, setSelectedReport] = useState(null)
    const [doctor, setDoctor] = useState(Doctor.create({}))

    useEffect(() => {
        // Update the document title using the browser API
        getReportListReq()
        // eslint-disable-next-line
    }, []);

    const getReportListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getReportListResponse = await getPatientReportList(props.store.baseUrl, props.store.token, props.store.patient[0].id)
        console.log("getReportListResponse: ", getReportListResponse)
        if (getReportListResponse !== null && getReportListResponse !== undefined) {
            if (getReportListResponse.status === 200) {
                let raporList = getReportListResponse.data
                for (let index = 0; index < raporList.length; index++) {
                    const rapor = raporList[index];
                    if (rapor.appointmentId === props.raporId) {
                        setSelectedReport(rapor)

                        let doctorResponse = await getPatientDoctorInfo(props.store.baseUrl, props.store.token,
                            props.store.patient[0].id, rapor.doctorId)
                        if (doctorResponse !== null && doctorResponse !== undefined) {
                            if (doctorResponse.status === 200) {
                                console.log("doctorResponse.data: ", doctorResponse.data)
                                setDoctor(doctorResponse.data)
                                window.print()
                            }
                        }
                    }
                }
            }else if (getReportListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <Typography style={{ fontWeight: "bold" }}>RAPOR</Typography>
                    <Typography variant='body2'>
                        WholeMedics AŞ'ye Ait MedScreenWM <br /> Platformunda İmzalanmıştır. SB Tescil No.********
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <QRCode value={"/danisan/rapor/" + props.raporId} size={100} style={{ marginRight: "10px" }} />
                </Grid>
            </Grid>
            <Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px" }}>
                <Grid item xs={4}>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Doktor Bilgileri</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Dr. Adı Soyadı:
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'> {(selectedReport?.titleName ? (selectedReport.titleName + " ") : "") + selectedReport?.doctorName + " " + selectedReport?.doctorSurname}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>İletişim Bilgileri:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {doctor.address.address + " " + doctor.address.neighbourhoodName + " " + doctor.address.districtName +
                                    " " + doctor.address.cityName} <br />
                                {doctor.email} <br />
                                {doctor.mobilePhone}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Uzmanlık Bilgisi:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'> {selectedReport?.departmentName}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Diploma No:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {doctor.diploma.diplomaNo}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <img src={props.store.cdn + doctor.kase} style={{ width: "250px" }} alt="kase"/>

                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Hasta Bilgileri</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>T.C. No:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {props.store.patient[0].tckno}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Hasta Adı Soyadı:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {props.store.patient[0].name + " " + props.store.patient[0].surname}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Doğum Tarihi:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body2'>
                                {dayjs(new Date(props.store.patient[0].birthDate)).format("DD.MM.YYYY")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px", marginTop: "15px" }}>
                <Grid item xs={12}>
                    <Typography><b>Şikayet ve Bulgular</b></Typography>
                    <Typography variant='body1'>Şikayet: {selectedReport?.complaint} <br />
                        Bulgular: {selectedReport?.symptom}</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Typography><b>Tanı: </b></Typography>
                    <Typography variant='body1'>
                        {selectedReport?.diagnosisName}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Typography><b>Tedavi: </b></Typography>
                    <Typography variant='body1'>
                        {selectedReport?.treatment}
                    </Typography>
                </Grid>

            </Grid>

            <Grid container spacing={2} style={{ width: "100%", border: "1px solid gray", margin: "0", marginTop: "15px" }}>
                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "#000", fontWeight: "bold" }}>Rapor Tipi</FormLabel>
                        {(selectedReport?.reportType === 1) ? "Doğum Raporu" : (selectedReport?.reportType === 2) ? "Durum Raporu" : "İş Göremezlik Raporu"}
                    </FormControl>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={12}>

                    <Typography><b>Rapor Kararı: </b>

                        {selectedReport?.reportDecision}
                    </Typography>

                </Grid>
            </Grid>
        </div>
    )
}))